import { Grid } from '@chakra-ui/react'
import Transition from 'components/layout/Transition'
import { graphql } from 'gatsby'
import * as React from 'react'

const IndexPage = ({ data }) => {
  return (
    <Transition>
      <Grid mb={'5rem'} px={'5rem'} gap={'2rem'} gridRowGap={0} templateColumns={['repeat(8, 1fr)', null]}>
        {/*<Modules value={data.home._rawModules} />*/}
      </Grid>
    </Transition>
  )
}

export default IndexPage

export const query = graphql`
query {
  home: sanityHome {
    _rawModules(resolveReferences: {maxDepth: 10})
  }
}`

